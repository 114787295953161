﻿var dg = dg || {};
dg.ui = dg.ui || {};

(function () {

    if (!$.blockUI) {
        return;
    }

    $.extend($.blockUI.defaults, {
        message: ' ',
        css: {},
        overlayCSS: {
            backgroundColor: '#AAA',
            opacity: 0.3,
            cursor: 'wait'
        }
    });

    dg.ui.block = function (elm) {
        if (!elm) {
            $.blockUI();
        } else {
            $(elm).block();
        }
    };

    dg.ui.unblock = function (elm) {
        if (!elm) {
            $.unblockUI();
        } else {
            $(elm).unblock();
        }
    };

})();

(function () {

    if (!$.fn.spin) {
        return;
    }

    dg.libs = dg.libs || {};

    dg.libs.spinjs = {

        spinner_config: {
            lines: 11,
            length: 0,
            width: 8,
            radius: 16,
            corners: 1.0,
            trail: 60,
            speed: 1.2
        },

        //Config for busy indicator in element's inner element that has '.dg-busy-indicator' class.
        spinner_config_inner_busy_indicator: {
            lines: 11,
            length: 0,
            width: 4,
            radius: 7,
            corners: 1.0,
            trail: 60,
            speed: 1.2
        }

    };

    dg.ui.setBusy = function (elm, optionsOrPromise) {
        optionsOrPromise = optionsOrPromise || {};
        if (optionsOrPromise.always || optionsOrPromise['finally']) { //Check if it's promise
            optionsOrPromise = {
                promise: optionsOrPromise
            };
        }

        var options = $.extend({}, optionsOrPromise);

        if (!elm) {
            if (options.blockUI !== false) {
                dg.ui.block();
            }

            $('body').spin(dg.libs.spinjs.spinner_config);
        } else {
            var $elm = $(elm);
            var $busyIndicator = $elm.find('.dg-busy-indicator');
            if ($busyIndicator.length) {
                $busyIndicator.spin(dg.libs.spinjs.spinner_config_inner_busy_indicator);
            } else {
                if (options.blockUI !== false) {
                    dg.ui.block(elm);
                }

                $elm.spin(dg.libs.spinjs.spinner_config);
            }
        }

        if (options.promise) { //Supports Q and jQuery.Deferred
            if (options.promise.always) {
                options.promise.always(function () {
                    dg.ui.clearBusy(elm);
                });
            } else if (options.promise['finally']) {
                options.promise['finally'](function () {
                    dg.ui.clearBusy(elm);
                });
            }
        }
    };

    dg.ui.clearBusy = function (elm) {
        if (!elm) {
            dg.ui.unblock();
            $('body').spin(false);
        } else {
            var $elm = $(elm);
            var $busyIndicator = $elm.find('.dg-busy-indicator');
            if ($busyIndicator.length) {
                $busyIndicator.spin(false);
            } else {
                dg.ui.unblock(elm);
                $elm.spin(false);
            }
        }
    };

})();
