﻿var dg = dg || {};

/* UTILS ***************************************************/

dg.utils = dg.utils || {};

/**
 * Method to check localstorage is accessible
 * @returns {boolean} 
 */
dg.utils.isLocalStorageAccessible = function () {
    if (window.isLocalStorageAccessible === undefined) {
        try {
            var x = 'testlocalstorage_accessible' + Date.now();
            localStorage.setItem(x, x);
            var y = localStorage.getItem(x);
            localStorage.removeItem(x);
            if (x !== y) {
                throw new Error();
            }
            window.isLocalStorageAccessible = true;
        } catch (exception) {
            window.isLocalStorageAccessible = false;
        }
    }

    return window.isLocalStorageAccessible;
};

/**
     * Sets a cookie value for given key.          
     * @param {string} key
     * @param {string} value 
     * @param {Date} expireDate (optional). If not specified the cookie will expire at the end of session.
     * @param {string} path (optional)
     */
dg.utils.setCookieValue = function (key, value, expireDate, path, domain) {
    var cookieValue = encodeURIComponent(key) + '=';

    if (value) {
        cookieValue = cookieValue + encodeURIComponent(value);
    }

    if (expireDate) {
        cookieValue = cookieValue + "; expires=" + expireDate.toUTCString();
    }

    if (path) {
        cookieValue = cookieValue + "; path=" + path;
    }

    if (domain) {
        cookieValue = cookieValue + "; domain=" + domain;
    }

    document.cookie = cookieValue;
};

/**
 * Gets a cookie with given key. 
 * @param {string} key
 * @returns {string} Cookie value or null
 */
dg.utils.getCookieValue = function (key) {
    var equalities = document.cookie.split('; ');
    for (var i = 0; i < equalities.length; i++) {
        if (!equalities[i]) {
            continue;
        }

        var splitted = equalities[i].split('=');
        if (splitted.length != 2) {
            continue;
        }

        if (decodeURIComponent(splitted[0]) === key) {
            return decodeURIComponent(splitted[1] || '');
        }
    }

    return null;
};

/**
 * Deletes cookie for given key. 
 * @param {string} key
 * @param {string} path (optional)
 */
dg.utils.deleteCookie = function (key, path) {
    var cookieValue = encodeURIComponent(key) + '=';

    cookieValue = cookieValue + "; expires=" + (new Date(new Date().getTime() - 86400000)).toUTCString();

    if (path) {
        cookieValue = cookieValue + "; path=" + path;
    }

    document.cookie = cookieValue;
}

/**
 * Gets the domain of given url
 * @param {string} url 
 * @returns {string} 
 */
dg.utils.getDomain = function (url) {
    var domainRegex = /(https?:){0,1}\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
    var matches = domainRegex.exec(url);
    return (matches && matches[2]) ? matches[2] : '';
};


(function ($) {

    if (!$) {
        return;
    }

    /* JQUERY ENHANCEMENTS ***************************************************/
    // dg.ajax -> uses $.ajax ------------------------------------------------

    dg.ajax = function (userOptions) {
        userOptions = userOptions || {};

        var options = $.extend({}, dg.ajax.defaultOpts, userOptions);
        options.success = undefined;
        options.error = undefined;

        return $.Deferred(function ($dfd) {
            $.ajax(options)
                .done(function (data, textStatus, jqXHR) {
                    if (data.__dg) {
                        dg.ajax.handleResponse(data, userOptions, $dfd, jqXHR);
                    } else {
                        $dfd.resolve(data);
                        userOptions.success && userOptions.success(data);
                    }
                }).fail(function (jqXHR) {
                    if (jqXHR.responseJSON && jqXHR.responseJSON.__dg) {
                        dg.ajax.handleResponse(jqXHR.responseJSON, userOptions, $dfd, jqXHR);
                    } else {
                        dg.ajax.handleNondgErrorResponse(jqXHR, userOptions, $dfd);
                    }
                });
        });
    };

    $.extend(dg.ajax,
        {
            defaultOpts: {
                dataType: 'json',
                type: 'POST',
                contentType: 'application/json'
            },
            defaultError: {
                message: 'An error has occurred!',
                details: 'Error detail not sent by server.'
            },

            defaultError401: {
                message: 'You are not authenticated!',
                details: 'You should be authenticated (sign in) in order to perform this operation.'
            },

            defaultError403: {
                message: 'You are not authorized!',
                details: 'You are not allowed to perform this operation.'
            },

            defaultError404: {
                message: 'Resource not found!',
                details: 'The resource requested could not found on the server.'
            },

            logError: function (error) {
                dg.log.error(error);
            },

            showError: function (error) {
                if (error.details) {
                    return dg.message.error(error.details, error.message);
                } else {
                    return dg.message.error(error.message || dg.ajax.defaultError.message);
                }
            },

            handleNondgErrorResponse: function (jqXHR, userOptions, $dfd) {
                if (userOptions.dgHandleError !== false) {
                    switch (jqXHR.status) {
                        case 401:
                            dg.ajax.handleUnAuthorizedRequest(
                                dg.ajax.showError(dg.ajax.defaultError401),
                                dg.appPath
                            );
                            break;
                        case 403:
                            dg.ajax.showError(dg.ajax.defaultError403);
                            break;
                        case 404:
                            dg.ajax.showError(dg.ajax.defaultError404);
                            break;
                        default:
                            dg.ajax.showError(dg.ajax.defaultError);
                            break;
                    }
                }

                $dfd.reject.apply(this, arguments);
                userOptions.error && userOptions.error.apply(this, arguments);
            },

            handleUnAuthorizedRequest: function (messagePromise, targetUrl) {
                if (messagePromise) {
                    messagePromise.done(function () {
                        dg.ajax.handleTargetUrl(targetUrl);
                    });
                } else {
                    dg.ajax.handleTargetUrl(targetUrl);
                }
            },

            handleResponse: function (data, userOptions, $dfd, jqXHR) {
                if (data) {
                    if (data.success === true) {
                        $dfd && $dfd.resolve(data.result, data, jqXHR);
                        userOptions.success && userOptions.success(data.result, data, jqXHR);

                        if (data.targetUrl) {
                            dg.ajax.handleTargetUrl(data.targetUrl);
                        }
                    } else if (data.success === false) {
                        var messagePromise = null;

                        if (data.error) {
                            if (userOptions.dgHandleError !== false) {
                                messagePromise = dg.ajax.showError(data.error);
                            }
                        } else {
                            data.error = dg.ajax.defaultError;
                        }

                        dg.ajax.logError(data.error);

                        $dfd && $dfd.reject(data.error, jqXHR);
                        userOptions.error && userOptions.error(data.error, jqXHR);

                        if (jqXHR.status === 401 && userOptions.dgHandleError !== false) {
                            dg.ajax.handleUnAuthorizedRequest(messagePromise, data.targetUrl);
                        }
                    } else { //not wrapped result
                        $dfd && $dfd.resolve(data, null, jqXHR);
                        userOptions.success && userOptions.success(data, null, jqXHR);
                    }
                } else { //no data sent to back
                    $dfd && $dfd.resolve(jqXHR);
                    userOptions.success && userOptions.success(jqXHR);
                }
            },
        });

})(jQuery);