﻿var dg = dg || {};

(function ($) {
    if (!sweetAlert || !$) {
        return;
    }


    dg.message = dg.message || {};

    /* DEFAULTS *************************************************/

    dg.libs = dg.libs || {};
    dg.libs.sweetAlert = {
        'default': {
        },
        info: {
            icon: 'info'
        },
        success: {
            icon: 'success'
        },
        warn: {
            icon: 'warning'
        },
        error: {
            icon: 'error'
        }
    };

    /* MESSAGE **************************************************/

    var showMessage = function (type, message, title, content = null) {
        //if (!title) {
        //    title = message;
        //    message = undefined;
        //}

        var opts = $.extend(
            {},
            dg.libs.sweetAlert['default'],
            dg.libs.sweetAlert[type],
            {
                title: title,
                text: message,
                content: content
            }
        );

        return $.Deferred(function ($dfd) {
            sweetAlert(opts).then(function () {
                $dfd.resolve();
            });
        });
    }

    dg.message.info = function (message, title) {
        return showMessage('info', message, title);
    };

    dg.message.success = function (message, title, content = null) {
        return showMessage('success', message, title, content);
    };

    dg.message.warn = function (message, title) {
        return showMessage('warn', message, title);
    };

    dg.message.error = function (message, title) {
        return showMessage('error', message, title);
    };

})(jQuery);
